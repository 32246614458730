import React from 'react';

import {
  DynamicContentComponent
} from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import {
  ProductsSliderSection
} from '@lerna-monorepo/common/components/productsSliderSection/productsSliderSection.component';
import {
  ButtonIconAdvertisingSection
} from '@lerna-monorepo/common/components/buttonIconAdvertisingSection/buttonIconAdvertisingSection.component';
import { Color } from '@lerna-monorepo/common/theme';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';
import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';

import {
  DynamicContentComponentContainer,
  DynamicContentGroupContainer,
  HalfBox,
  RowContainer,
} from './productsList.styled';
import { ProductsListPageData } from './productsList.types';

const ProductsListPage: React.FC<ProductsListPageData> = (pageData: ProductsListPageData) => {
  const { data, productsBasePath, currentLanguage } = pageData.pageContext;
  const {
    headerContent,
    contentBelowCategories,
    buttonIconAdvertisingSection,
    productListCategories,
  } = data;
  const { sectionRef, handleScroll } = useScrollToSection();

  const renderContent = (content: DynamicContent[]) => content.map((caseStudyContentItem: DynamicContent) => (
    <DynamicContentComponentContainer
      key={`${caseStudyContentItem.header.bigHeader.mainContent}${caseStudyContentItem.header.bigHeader.descriptionContent}`}
    >
      <DynamicContentComponent
        header={caseStudyContentItem.header}
        dynamicContent={caseStudyContentItem.dynamicContent}
      />
    </DynamicContentComponentContainer>
  ));

  const renderContentContainer = renderWhenTrue(() => (
    <DynamicContentGroupContainer>
      {renderContent(contentBelowCategories)}
      <HalfBox />
    </DynamicContentGroupContainer>
  ));

  const renderProductCategoriesRows = () => productListCategories.map((productCategoryList) => (
    <ProductsSliderSection
      header={productCategoryList.header}
      description={productCategoryList.description}
      products={productCategoryList.productList}
      key={productCategoryList.header}
      boxesBaseUrl={productsBasePath}
      currentLanguage={currentLanguage}
    />
  ));

  const renderProductsCategoriesContainer = renderWhenTrue(() => (
    <RowContainer ref={sectionRef}>
      {renderProductCategoriesRows()}
      <HalfBox />
    </RowContainer>
  ));

  return (
    <>
     <PhotoHeader
        subheaderContent={headerContent.subheaderContent}
        headerContent={headerContent.headerContent}
        buttonLabel={headerContent.buttonLabel}
        background={headerContent.background}
        videoBackground={headerContent.videoBackground}
        halfBoxBackgroundColor={Color.veryLightGrey2}
        handleScroll={handleScroll}
      />
      {renderProductsCategoriesContainer(!!productListCategories)}
      {renderContentContainer(!!contentBelowCategories)}
      <ButtonIconAdvertisingSection
        icon={buttonIconAdvertisingSection.icon}
        textArray={buttonIconAdvertisingSection.textArray}
        buttonLabel={buttonIconAdvertisingSection.buttonLabel}
        buttonLink={buttonIconAdvertisingSection.buttonLink}
        backgroundColor={contentBelowCategories ? Color.veryLightGrey2 : Color.white}
      />
    </>
  );
};

export default ProductsListPage;
