import styled from 'styled-components';

import { Color } from '@lerna-monorepo/common/theme';

export const DynamicContentGroupContainer = styled.div`
  padding: 150px 0;
  background-color: ${Color.white};
  position: relative;
`;

export const DynamicContentComponentContainer = styled.div`
  & + & {
    padding-top: 100px;
  }
`;

export const HalfBox = styled.div`
  width: 50%;
  background-color: ${Color.white};
  height: 45px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const RowContainer = styled.div`
  padding: 150px 0;
  background-color: ${Color.veryLightGrey2};
  position: relative;
`;
